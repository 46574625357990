import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImgListService {

  constructor() { }

  getImgList(): String[] {
    let returnList = [
      "../../assets/img/pic1.jpg","../../assets/img/pic2.jpg","../../assets/img/pic3.jpg","../../assets/img/pic4.jpg","../../assets/img/pic5.jpg","../../assets/img/pic6.jpg",
      "../../assets/img/pic7.jpg","../../assets/img/pic8.jpg","../../assets/img/pic9.jpg","../../assets/img/pic10.jpg","../../assets/img/pic11.jpg","../../assets/img/pic12.jpg"
    ]


    return returnList
  }  
  

}
