import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageModule } from './homepage/homepage.module';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ImpressumModule } from './impressum/impressum.module';
import { DsgvoModule } from './dsgvo/dsgvo.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { GalleryComponent } from './gallery/gallery.component';
import { CommonModule } from '@angular/common';
import { GalleryModule } from './gallery/gallery.module';


@NgModule({
  declarations: [
    AppComponent, FooterComponent, BannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomepageModule,
    NgbModule,
    ImpressumModule,
    DsgvoModule,
    AngularFontAwesomeModule,
    CommonModule,
    GalleryModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
