import { Component, OnInit } from '@angular/core';
import { ImgListService } from '../img-list.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  private imgList: Array<String> 
  private imgListService: ImgListService
  constructor(imgListService: ImgListService) {
    this.imgListService = imgListService;
   }

  public ngOnInit() {
    this.imgList = this.imgListService.getImgList();
  }

  public getImgList(){
    this.imgList = this.imgListService.getImgList();
  }


}
