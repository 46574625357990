import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsgvoComponent } from './dsgvo.component';

@NgModule({
  declarations: [DsgvoComponent],
  imports: [
    CommonModule
  ]
})

export class DsgvoModule { }
