import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageModule } from './homepage/homepage.module';
import { HomepageComponent } from './homepage/homepage.component';
import { DsgvoModule } from './dsgvo/dsgvo.module';
import { ImpressumModule } from './impressum/impressum.module';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { GalleryComponent } from './gallery/gallery.component';


const routes: Routes = [
  { path: '', component: HomepageComponent},
  { path: 'dsgvo', component: DsgvoComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'bilder', component: GalleryComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), HomepageModule, ImpressumModule, DsgvoModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
