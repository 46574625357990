import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent, NgbdModalContent } from './gallery.component';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [GalleryComponent, NgbdModalContent],
  imports: [
    CommonModule,
    BrowserModule
  ],
  entryComponents: [GalleryComponent, NgbdModalContent],
})
export class GalleryModule { }
