import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './homepage.component';


@NgModule({
  declarations: [HomepageComponent],
  imports: [
    CommonModule,
  ],
  entryComponents: [HomepageComponent],
})
export class HomepageModule { }
