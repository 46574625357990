import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ImgListService } from '../img-list.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
  <div>
  <img class="img-responsive modal-img" src={{src}}>
  </div>
  `
})
export class NgbdModalContent {
  @Input() src;

  constructor(public activeModal: NgbActiveModal) {}
}


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
   
  private imgList: Array<String> 
  private imgListService: ImgListService

  constructor(imgListService: ImgListService, private modalService: NgbModal) {
    this.imgListService = imgListService;
   }

  public ngOnInit() {
    this.imgList = this.imgListService.getImgList();
  }

  public getImgList(){
    this.imgList = this.imgListService.getImgList();
  }

  public openModal(src: String)
  {
    const modalRef = this.modalService.open(NgbdModalContent, { size: 'lg' , windowClass : "myCustomModalClass", centered: true});
    modalRef.componentInstance.src = src;


  }

}
